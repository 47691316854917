<template>
  <LiefengContent>
    <template #title>议事厅流程</template>
    <template #toolsbarRight>
      <Button
        type="primary"
        icon="ios-create-outline"
        @click="changeBaseInfo"
        >{{buttonRoot == '1001' && $route.query.startend == 'start' ? '修改基本内容' : buttonRoot == '1002' && $route.query.startend == 'start' && discussManageData.status == '2' ? '审核基本内容' : buttonRoot == '1003' && $route.query.startend == 'start' && discussManageData.status == '3' ? '发布基本信息': '查看基本内容'}}</Button
      >
      <Button
        type="error"
        icon="ios-arrow-back"
        @click="$router.push('/discussionindex?menuId=' + $route.query.menuId)"
        >返回</Button
      >
    </template>
    <template #contentArea>
      <span>议事标题：</span>
      <h3 style="display: inline-block; margin-right: 20px">
        {{ discussManageData.title }}
      </h3>
      <br />
      <span
        >创建时间：{{
          discussManageData.gmtCreate
            ? discussManageData.gmtCreate
            : "" | filterTime(that)
        }}</span
      >
      <Tabs v-model="tabName" @on-click="toggleTab" class="tab">
        <TabPane label="议事进程" name="process">
          <Dropdown
            style="margin-left: 20px"
            trigger="click"
            transfer
            @on-click="addNextDiscuss"
            v-if="$route.query.startend == 'start' && buttonRoot == '1001'"
          >
            <Button type="primary">
              新增下一阶段
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="nextDiscussNoticeStatus"
                >新增议事讨论</DropdownItem
              >
              <DropdownItem name="nextDiscussVoteStatus"
                >新增议事投票</DropdownItem
              >
              <DropdownItem name="nextDiscussAnnStatus"
                >新增议事公告</DropdownItem
              >
            </DropdownMenu>
          </Dropdown>
          <Button
            type="error"
            style="margin-left: 16px"
            @click="endDiscuss"
            v-if="$route.query.startend == 'start'"
            >结束议事</Button
          >
          <div v-if="discussManageData.child" style="margin: 14px 10px">
            <Timeline>
              <div
                v-for="(item, index) in discussManageData.child"
                :key="index"
                class="time-line"
              >
                <div style="width: 132px;text-align: center" v-if="item.businessType == '1'">
                  <span style="color: #aaa; font-weight: bold">议事讨论</span>
                  <span
                    :style="{
                      color:
                        item.status == '6' || item.status == '7' ? 'red' : '',
                      marginLeft: '10px',
                    }"
                    >{{
                      item.status == "1"
                        ? "待提交"
                        : item.status == "2"
                        ? "待审核"
                        : item.status == "3"
                        ? "待发布"
                        : item.status == "4"
                        ? "进行中"
                        : item.status == "5"
                        ? "已结束"
                        : item.status == "6"
                        ? "审核驳回"
                        : item.status == "7"
                        ? "发布驳回 "
                        : ""
                    }}</span
                  >
                  <br />
                  <Button type="success" v-if="item.status == '2' && buttonRoot == '1002'"  style="margin-top: 14px; width: 102px" @click="updateVoteFn(item.businessId,'3')">通过</Button>
                  <Button type="success" v-if="item.status == '3' && buttonRoot == '1003'"  style="margin-top: 14px; width: 102px" @click="updateVoteFn(item.businessId,'4')">发布</Button>
                  <Button type="error" v-if="(item.status == '2' && buttonRoot == '1002') || (item.status == '3' && buttonRoot == '1003')  " style="margin-top: 14px; width: 102px" @click="openReject(item.businessId,item.status == '2' ? '6' : '7')">驳回</Button>
                  <Button
                    type="primary"
                    style="margin-top: 14px; width: 102px"
                    @click="discussSetting(item.businessId, 'speakManage')"
                    >发言管理</Button
                  >
                  <Button
                    type="warning"
                    style="margin: 14px 0"
                    @click="discussSetting(item.businessId, 'discussSetting')"
                    >议事厅设置</Button
                  >
                  <Button
                    type="info"
                    style="margin-bottom: 14px"
                    @click="discussSetting(item.businessId, 'managerManage')"
                    >管理员管理</Button
                  >
                  <Button
                    type="success"
                    style="margin-bottom: 14px"
                    @click="discussSetting(item.businessId, 'contactManage')"
                    >户代表管理</Button
                  >
                  <Button
                    type="error"
                    style="margin-bottom: 14px"
                    v-if="$route.query.startend == 'start'"
                    @click="deleteVoteOption(item.businessId,index)"
                    >删除此阶段</Button
                  >
                </div>
                <div style="width: 132px;text-align: center" v-else-if="item.businessType == '2'">
                  <span style="color: #aaa; font-weight: bold">议事投票</span>
                  <span
                    :style="{
                      color:
                        item.status == '6' || item.status == '7' ? 'red' : '',
                      marginLeft: '10px',
                    }"
                    >{{
                      item.status == "1"
                        ? "待提交"
                        : item.status == "2"
                        ? "待审核"
                        : item.status == "3"
                        ? "待发布"
                        : item.status == "4"
                        ? "已发布"
                        : item.status == "5"
                        ? "已结束"
                        : item.status == "6"
                        ? "审核驳回"
                        : item.status == "7"
                        ? "发布驳回 "
                        : ""
                    }}</span
                  >
                  <br />
                   <Button type="success" v-if="item.status == '2' && buttonRoot == '1002'"  style="margin-top: 14px; width: 102px" @click="updateVoteFn(item.businessId,'3')">通过</Button>
                  <Button type="success" v-if="item.status == '3' && buttonRoot == '1003'"  style="margin-top: 14px; width: 102px" @click="updateVoteFn(item.businessId,'4')">发布</Button>
                 <Button type="error" v-if="(item.status == '2' && buttonRoot == '1002') || (item.status == '3' && buttonRoot == '1003')  " style="margin-top: 14px; width: 102px" @click="openReject(item.businessId,item.status == '2' ? '6' : '7')">驳回</Button>
                  <Button
                    type="primary"
                    style="margin-top: 14px; width: 102px"
                    @click="
                      $router.push({
                        path: '/discussionvotinglist',
                        query: {
                          businessId: item.businessId,
                          parentBusinessId: $route.query.businessId,
                          menuId: $route.query.menuId,
                          startend: $route.query.startend,
                        },
                      })
                    "
                    >投票名单</Button
                  >
                  <Button
                    type="warning"
                    style="margin: 14px 0"
                    @click="
                      changeVoteOption(
                        item.voteInfo.businessType,
                        item.voteInfo.businessCode,
                        item.businessId
                      )
                    "
                  >
                    {{
                      $route.query.startend == "end" || buttonRoot != '1001'
                        ? "查看投票项"
                        : "修改投票项"
                    }}</Button
                  >
                  <Button
                    type="error"
                    v-if="$route.query.startend == 'start'"
                    style="margin-bottom: 14px"
                    @click="deleteVoteOption(item.businessId,index)"
                    >删除此阶段</Button
                  >
                </div>
                <div style="width: 132px;text-align: center" v-else>
                  <span style="color: #aaa; font-weight: bold">议事公告</span>
                  <span
                    :style="{
                      color:
                        item.status == '6' || item.status == '7' ? 'red' : '',
                      marginLeft: '10px',
                    }"
                    >{{
                      item.status == "1"
                        ? "待提交"
                        : item.status == "2"
                        ? "待审核"
                        : item.status == "3"
                        ? "待发布"
                        : item.status == "4"
                        ? "已发布"
                        : item.status == "5"
                        ? "已结束"
                        : item.status == "6"
                        ? "审核驳回"
                        : item.status == "7"
                        ? "发布驳回 "
                        : ""
                    }}</span
                  >
                  <br />
                  <Button type="success" v-if="item.status == '2' && buttonRoot == '1002'"  style="margin-top: 14px; width: 102px" @click="updateVoteFn(item.businessId,'3')">通过</Button>
                  <Button type="success" v-if="item.status == '3' && buttonRoot == '1003'"  style="margin-top: 14px; width: 102px" @click="updateVoteFn(item.businessId,'4')">发布</Button>
                   <Button type="error" v-if="(item.status == '2' && buttonRoot == '1002') || (item.status == '3' && buttonRoot == '1003')  " style="margin-top: 14px; width: 102px" @click="openReject(item.businessId,item.status == '2' ? '6' : '7')">驳回</Button>
                  <Button
                    type="primary"
                    style="margin-top: 14px; width: 102px"
                    @click="
                      businessId = item.businessId;
                      commentManageStatus = true;
                    "
                    >评论管理</Button
                  >
                  <Button
                    type="warning"
                    @click="changeDetailFn(item.businessId)"
                    :disabled="$route.query.startend == 'end'"
                    style="margin: 14px 0; width: 102px"
                    >修改详情</Button
                  >
                  <Button
                    type="error"
                    v-if="$route.query.startend == 'start'"
                    style="margin-bottom: 14px"
                    @click="deleteVoteOption(item.businessId,index)"
                    >删除此阶段</Button
                  >
                </div>

                <TimelineItem color="#2d8cf0" style="flex: 1">
                  <span slot="dot">{{
                    discussManageData.child.length - index
                  }}</span>
                  <span style="margin-right: 16px"
                    >{{ item.startTime }} ~ {{ item.endTime }}</span
                  >
                  <Button
                    v-if="$route.query.startend == 'start'"
                    type="info"
                    size="small"
                    @click="
                      changeTime(item.startTime, item.endTime, item.businessId)
                    "
                    >修改时间</Button
                  >
                  <!-- 议事讨论 -->
                  <div
                    v-if="item.businessType == '1'"
                    style="line-height: 28px"
                  >
                    <div>
                      当前发言数：<span style="color: #aaa">{{
                        item.recordCount
                      }}</span>
                    </div>
                    <div>议事厅人数：{{ item.participantCount }}</div>
                    <div>管理员人数：{{ item.adminCount }}</div>
                    <div>
                      <span style="margin-right: 16px">发送图片设置:</span>
                      <RadioGroup
                        :value="item.chatImg"
                        @on-change="
                          (val) =>
                            changeDiscussRule({
                              businessId: item.businessId,
                              chatImg: val,
                            })
                        "
                      >
                        <Radio
                          label="1"
                          :disabled="$route.query.startend == 'end'"
                          style="margin-right: 16px"
                          >可以发图</Radio
                        >
                        <Radio
                          label="2"
                          :disabled="$route.query.startend == 'end'"
                          >禁止发图</Radio
                        >
                      </RadioGroup>
                    </div>
                    <div>
                      <span style="margin-right: 16px">发言设置:</span>
                      <RadioGroup
                        :value="item.chatDiscuss"
                        @on-change="
                          (val) =>
                            changeDiscussRule({
                              businessId: item.businessId,
                              chatDiscuss: val,
                            })
                        "
                      >
                        <Radio
                          label="ON"
                          :disabled="$route.query.startend == 'end'"
                          style="margin-right: 16px"
                          >正常发言</Radio
                        >
                        <Radio
                          label="OFF"
                          :disabled="$route.query.startend == 'end'"
                          >全体禁言</Radio
                        >
                      </RadioGroup>
                    </div>
                  </div>
                  <div
                    v-else-if="item.businessType == '2'"
                    style="line-height: 28px; margin-top: 16px"
                  >
                    <List border item-layout="vertical">
                      <div slot="header">
                        <Radio :value="true">{{
                          item.voteInfo.voteType == "1" ? "单选" : "多选"
                        }}</Radio>
                        <Button
                          type="info"
                          style="margin-left: 10px"
                          v-if="$route.query.startend == 'start'"
                          @click="
                            changeVoteRule({
                              businessId: item.businessId,
                              voteType: item.voteInfo.voteType,
                              selectMinItem: item.voteInfo.selectMinItem,
                              selectMaxItem: item.voteInfo.selectMaxItem,
                            })
                          "
                          >修改投票规则</Button
                        >
                      </div>
                      <ListItem
                        v-for="(subItem, subIndex) in item.voteInfo.voteItemVos"
                        :key="subIndex"
                      >
                        <p>{{ subItem.title }}</p>
                        <img
                          :src="subItem.coverImg"
                          alt=""
                          style="display: block; max-width: 200px"
                        />
                        <Tooltip
                          :content="
                            '累计票数' +
                            item.voteInfo.voteTotal +
                            '票,当前项票数' +
                            subItem.vateTotal +
                            '票'
                          "
                          style="width: 100%"
                          placement="top"
                        >
                          <Progress
                            :percent="
                              item.voteInfo.voteTotal == 0
                                ? 0
                                : (Number(subItem.vateTotal) * 100) /
                                  Number(item.voteInfo.voteTotal)
                            "
                            :stroke-width="20"
                            status="active"
                            text-inside
                            style="line-height: 20px"
                          />
                        </Tooltip>
                      </ListItem>
                      <ListItem v-if="item.status == '6' || item.status == '7'">
                        <span style="color: red"
                          >驳回原因：{{ item.rejectCause }}</span
                        >
                      </ListItem>
                    </List>
                  </div>
                  <div v-else style="margin-top: 16px">
                    <div>
                      <div style="margin-bottom: 16px">
                        <span style="margin-right: 16px">评论设置:</span>
                        <i-switch
                          size="large"
                          :value="item.chatDiscuss"
                          true-value="ON"
                          false-value="OFF"
                          :disabled="$route.query.startend == 'end'"
                          @on-change="
                            (val) =>
                              changeDiscussRule({
                                businessId: item.businessId,
                                chatDiscuss: val,
                              })
                          "
                        >
                          <span slot="open">开启</span>
                          <span slot="close">关闭</span>
                        </i-switch>
                      </div>
                      <!-- <iframe
                        name="ueditor"
                        :id="'ueditor' + index"
                        src="/ueditor/index.html?titlename=详情信息"
                        style="width: 100%; height: 500px; border: 0px"
                        @load="loadUeditor(item, index)"
                      ></iframe> -->
                      <iframe
                      name="ueditor"
                      id="ueditor"
                      src="/mobileeditor/#/index?titlename=详情信息"
                      style="width: 100%; height: 700px; border: 0px"
                      @load="loadUeditor(item, index)"
                    ></iframe>
                    </div>
                  </div>
                </TimelineItem>
              </div>
              <div class="last-time">
                <TimelineItem>
                  <span slot="dot">开始</span>
                </TimelineItem>
              </div>
            </Timeline>
          </div>
        </TabPane>
        <TabPane label="议题基本内容" name="content">
          <div style="padding: 0 10px 10px">
            <span>信息名称：</span>
            {{ discussionBaseInfoForm.title }}
          </div>
          <div style="padding: 0 10px 10px">
            <span>信息来源：</span>
            {{ discussionBaseInfoForm.source }}
          </div>
          <div style="padding: 0 10px 10px">
            <span>发布范围：</span>
            <Button
              size="small"
              type="success"
              v-for="(item, index) in discussionBaseInfoForm.regions"
              :key="index"
              style="margin-right: 10px;margin-bottom: 10px"
              >{{ item }}</Button
            >
          </div>
          <!-- <iframe
            name="ueditor"
            id="discussionBaseInfoIframe"
            src="/ueditor/index.html?titlename=详情描述"
            style="width: 100%; height: calc(100vh - 270px); border: 0px"
            @load="loadBaseInfoIframe"
          ></iframe> -->
          <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=详情描述"
                 style="width: 100%; height: calc(100vh - 270px); border: 0px"
                @load="loadBaseInfoIframe"
              ></iframe>
        </TabPane>
        <TabPane label="操作记录" name="record">
          <LiefengTable
            :tableData="recordTableData"
            :talbeColumns="recordTalbeColumns"
            :loading="recordLoading"
            :fixTable="true"
            :hidePage="true"
          ></LiefengTable>
        </TabPane>
      </Tabs>

      <!-- 投票单选/多选规则设置 -->
      <LiefengModal
        :value="voteRuleStatus"
        @input="voteRuleStatusFn"
        width="400px"
      >
        <template #contentarea>
          <Form :model="voteRuleData">
            <FormItem style="margin-bottom: 16px">
              <span slot="label" class="validate">投票类型:</span>
              <RadioGroup v-model="voteRuleData.voteType">
                <Radio label="1">单选</Radio>
                <Radio label="2">多选</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem>
              <span class="validate" v-if="voteRuleData.voteType == '2'"
                >多选设置:</span
              >
              <span style="margin: 0 14px" v-if="voteRuleData.voteType == '2'">
                最少选择
                <InputNumber
                  :max="9999"
                  :min="2"
                  v-model="voteRuleData.selectMinItem"
                ></InputNumber>
                个选项
              </span>
              <span
                v-if="voteRuleData.voteType == '2'"
                style="margin: 10px 0 0 90px; display: block"
              >
                最多选择
                <InputNumber
                  :max="9999"
                  :min="2"
                  v-model="voteRuleData.selectMaxItem"
                ></InputNumber>
                个选项
              </span>
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="voteRuleStatus = false"
            >取消修改</Button
          >
          <Button type="primary" @click="saveVoteRule">保存修改</Button>
        </template>
      </LiefengModal>
      <!-- 修改时间 -->
      <LiefengModal
        title="议事时间设置"
        width="500"
        :value="timeStatus"
        @input="
          (status) => {
            timeStatus = status;
          }
        "
      >
        <template #contentarea>
          <Form inline :label-width="120">
            <FormItem>
              <span slot="label" class="validate">议事时限:</span>
              <DatePicker
                type="datetimerange"
                v-model="timeArr"
                format="yyyy-MM-dd HH:mm"
                placeholder="请选择议事开始时间和结束时间"
                style="width: 350px"
                transfer
              ></DatePicker>
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="timeStatus = false"
            >取消</Button
          >
          <Button type="primary" @click="saveTimeChange">确定</Button>
        </template>
      </LiefengModal>

      <!-- 新增下一阶段——讨论 -->
      <LiefengModal
        title="下阶段议事讨论"
        width="500"
        :value="nextDiscussNoticeStatus"
        @input="
          (status) => {
            nextDiscussNoticeStatus = status;
          }
        "
      >
        <template #contentarea>
          <Form inline :label-width="120">
            <FormItem>
              <span slot="label" class="validate">议事讨论时限:</span>
              <DatePicker
                type="datetimerange"
                v-model="discussNoticeTime"
                format="yyyy-MM-dd HH:mm"
                placeholder="请选择讨论开始时间和结束时间"
                style="width: 350px"
                transfer
              ></DatePicker>
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="nextDiscussNoticeStatus = false"
            >取消</Button
          >
          <Button type="primary" @click="saveNextDiscussNotice">确定</Button>
        </template>
      </LiefengModal>
      <!-- 新增下一阶段——投票 -->
      <LiefengModal
        :title="voteTitle"
        width="80%"
        height="calc(100vh - 100px)"
        :value="nextDiscussVoteStatus"
        @input="nextDiscussVoteStatusFn"
        class="modal"
      >
        <template #contentarea>
          <NextDiscussVote
            v-if="nextDiscussVoteStatus"
            :buttonRoot="buttonRoot"
            :showName="showName"
            :parentBusinessId="parentBusinessId"
            :businessType="businessType"
            :businessCode="businessCode"
            :businessId="businessId"
            @saveNextSuccess="saveNextSuccess"
            ref="nextDiscussVote"
          ></NextDiscussVote>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="
              cancelModalTip = $route.query.startend == 'start' && buttonRoot == '1001' ? true : false;
              nextDiscussVoteStatusFn(false);
            "
            >{{$route.query.startend == 'start' && buttonRoot == '1001' ? '取消' : '关闭'}}</Button
          >
          <Button
            type="primary"
            @click="saveNextVote"
            v-if="$route.query.startend == 'start' && buttonRoot == '1001'"
            >确定</Button
          >
        </template>
      </LiefengModal>
      <!-- 新增下一阶段——公告 -->
      <LiefengModal
        title="下阶段议事公告"
        width="80%"
        height="calc(100vh - 100px)"
        :value="nextDiscussAnnStatus"
        @input="nextDiscussAnnStatusFn"
      >
        <template #contentarea>
          <NextDiscussAnn
            v-if="nextDiscussAnnStatus"
            :parentBusinessId="parentBusinessId"
            @saveNextSuccess="saveNextSuccess"
            ref="nextDiscussAnn"
          ></NextDiscussAnn>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="
              cancelModalTip = true;
              nextDiscussAnnStatusFn(false);
            "
            >取消</Button
          >
          <Button type="primary" @click="saveNextAnn">确定</Button>
        </template>
      </LiefengModal>
      <!-- 议事讨论——发言管理 -->
      <LiefengModal
        title=""
        :fullscreen="true"
        :value="discussSettingStatus"
        @input="discussSettingStatusFn"
        class="modal"
      >
        <template #contentarea>
          <DiscussSetting
            @changeDiscussRule="changeDiscussRule"
            v-if="discussSettingStatus"
            @closeDiscussSetting="discussSettingStatus = false"
            :showName="showName"
            :businessId="businessId"
            @saveNextSuccess="saveNextSuccess"
            ref="duscussSetting"
          ></DiscussSetting>
        </template>
      </LiefengModal>
      <!-- 修改基本内容 -->
      <LiefengModal
        title="议事厅基本信息"
        :fullscreen="true"
        :value="baseInfoStatus"
        @input="baseInfoStatusFn"
        class="modal"
      >
        <template #contentarea>
          <BaseInfo ref="baseInfo" v-if="baseInfoStatus" :buttonRoot="buttonRoot" :currentMenuName="currentMenuName" :businessId="businessId" @saveSuccessBaseInfo="saveSuccessBaseInfo" @toggleMenu='(name) => currentMenuName = name'></BaseInfo>
        </template>
        <template #toolsbar>
          <Button
            type="success"
            @click="backNextStep"
            style="margin-right: 10px"
            >{{ currentMenuName == "baseInfo" ? "下一步" : "上一步" }}</Button
          >
          <Button
            type="info"
            style="margin-right: 10px"
            @click="
              cancelModalTip = $route.query.startend == 'end' || buttonRoot != '1001' ? false : true;
              baseInfoStatusFn(false);
            "
            >关闭</Button
          >
          <Button type="error" style="margin-right: 10px" @click="openReject($route.query.businessId,buttonRoot == '1002' ? '6' : '7')" v-if="(buttonRoot == '1002' && discussManageData.status == '2') || (buttonRoot == '1003' && discussManageData.status == '3')">驳回</Button>
          <Button type="primary" @click="saveBaseInfo"  v-if="$route.query.startend == 'start' && buttonRoot == '1001'">提交</Button>
          <Button type="primary" @click="updateVoteFn($route.query.businessId,buttonRoot == '1002' ? '3' : '4')"  v-if="$route.query.startend == 'start' && ((buttonRoot == '1002' && discussManageData.status == '2') || (buttonRoot == '1003' && discussManageData.status == '3'))">{{buttonRoot == '1002' ? '通过' : '发布'}}</Button>
        </template>
      </LiefengModal>
      <!-- 评论管理 -->
      <LiefengModal
        title=""
        :fullscreen="true"
        :value="commentManageStatus"
        @input="commentManageStatusFn"
        class="modal"
      >
        <template #contentarea>
          <CommentManage
            @commentManageStatusFn="commentManageStatusFn"
            :businessId="businessId"
            v-if="commentManageStatus"
          ></CommentManage>
        </template>
      </LiefengModal>
      <!-- 修改详情 -->
      <LiefengModal
        title="修改详情"
        :fullscreen="true"
        :value="detailStatus"
        @input="detailStatusFn"
        class="modal"
      >
        <template #contentarea>
          <iframe
            v-if="detailStatus"
            name="ueditor"
            id="detailUeditor"
            src="/ueditor/editor.html"
            style="width: 100%; height: calc(100vh - 128px); border: 0px"
            @load="detailLoadIframe"
          ></iframe>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="
              cancelModalTip = true;
              detailStatusFn(false);
            "
            >取消</Button
          >
          <Button type="primary" @click="submitDetail">提交</Button>
        </template>
      </LiefengModal>

      <!-- 驳回投票 -->
      <LiefengModal
        title="议事驳回"
        :value="rejectStatus"
        @input="rejectStatusFn"
      >
        <template #contentarea>
          <Form ref="rejectForm" :label-width="100" :model="rejectForm" :rules="rejectValidate">
            <FormItem label="驳回原因" prop="rejectCause">
              <Input :maxlength="100" v-model.trim="rejectForm.rejectCause" placeholder="请输入驳回原因，限100字"></Input>
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="rejectStatusFn(false)">取消</Button>
            <Button type="primary" @click="saveReject">确定</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/discussionmanage')
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import LiefengContent from "@/components/LiefengContent";
import NextDiscussAnn from "@/views/discussion/childrens/nextDiscussAnn";
import NextDiscussVote from "@/views/discussion/childrens/nextDiscussVote";
import DiscussSetting from "@/views/discussion/childrens/discussSetting";
import CommentManage from "@/views/discussion/childrens/commentManage";
import BaseInfo from '@/views/discussion/childrens/baseInfo'
export default {
  data() {
    return {
      buttonRoot: '',
      that: this,
      discussManageData: {},
      tabName: "process",
      // 修改基本内容
      baseInfoStatus: false,
      currentMenuName: "baseInfo",

      // 投票规则设置单选多选
      voteRuleData: {},
      voteRuleStatus: false,

      // 新增下一阶段——讨论
      nextDiscussNoticeStatus: false,
      discussNoticeTime: [],
      parentBusinessId: "",

      // 修改时间
      timeStatus: false,
      timeArr: [],
      businessId: "",

      //  新增下一阶段——投票 || 更新
      nextDiscussVoteStatus: false,
      voteTitle: "",
      businessCode: "",
      businessType: "",
      // 新增下一阶段——公告
      nextDiscussAnnStatus: false,

      // 议事讨论——管理
      discussSettingStatus: false,
      showName: "",

      // 议事公告
      commentManageStatus: false,

      // 议事公告——修改详情
      detailStatus: false,
      detailContent: "",

      // 议题基本内容
      discussionBaseInfoForm: {},

      // 操作记录
      recordTableData: [],
      recordTalbeColumns: [
        {
          title: '操作人',
          key: 'name',
          width: 120,
          align: 'center'
        },
        {
          title: '操作时间',
          key: 'name',
           width: 120,
          align: 'center'
        },
        {
          title: '操作时间',
          key: 'name',
           width: 120,
          align: 'center'
        },
        {
          title: '操作内容',
          key: 'name',
           minWidth: 200,
          align: 'center'
        },
         {
          title: '操作类型',
          key: 'name',
           width: 120,
          align: 'center'
        },
         {
          title: '操操作议事阶段',
          key: 'name',
           width: 160,
          align: 'center'
        },
      ],
      recordLoading: false,

      // 议事投票驳回
      rejectStatus: false,
      rejectForm: {rejectCause: ''},
      rejectValidate: {
        rejectCause: [
          {required: true, message:'请输入驳回原因', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    rejectStatusFn(status) {
      if(!status) {
        this.rejectForm = {rejectCause: ''};
      }
      this.rejectStatus = status;
    },
    openReject(businessId,status) {
      this.rejectForm.businessId = businessId;
      this.rejectForm.status = status;
      this.$refs.rejectForm.resetFields();
      this.rejectStatus = true;
    },
    saveReject() {
      this.$refs.rejectForm.validate(status => {
        if(status) {
          this.$post("/sendmsg/pc/chat/discussBusiness/updateStatus", {
            ...this.rejectForm,
            staffId: parent.vue.loginInfo.userinfo.custGlobalId,
            staffName: parent.vue.loginInfo.userinfo.realName,
          }).then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "操作成功",
              });
              setTimeout(() => {
                this.$Message.destroy();
                this.rejectStatusFn(false);
                this.baseInfoStatusFn(false);
                this.getProcessData()
              },500)
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          });
        }
      });
      
    },
    // 通过议事
    updateVoteFn(businessId,status) {
      this.$post("/sendmsg/pc/chat/discussBusiness/updateStatus", {
            businessId: businessId,
            staffId: parent.vue.loginInfo.userinfo.custGlobalId,
            staffName: parent.vue.loginInfo.userinfo.realName,
            status: status,
          }).then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "操作成功",
              });
              setTimeout(() => {
                this.$Message.destroy();
                this.baseInfoStatusFn(false);
                this.getProcessData()
              },500)
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          });
    },
    // 议题基本内容富文本
    loadBaseInfoIframe() {
      if (this.discussionBaseInfoForm.content != undefined) {
        var v_Obj = document.getElementById("discussionBaseInfoIframe")
          .contentWindow;
        v_Obj.setContent(this.discussionBaseInfoForm.content);
        v_Obj.setPreview(true);
      } else {
        setTimeout(this.loadBaseInfoIframe, 300);
      }
    },
    // 提交详情
    submitDetail() {
      var v_Obj = document.getElementById("detailUeditor").contentWindow;
      this.detailContent = v_Obj.getContent();
      if (!this.detailContent) {
        this.$Message.error({
          background: true,
          content: "请输入详情内容",
        });
        return;
      }
      this.changeDiscussRule({
        businessId: this.businessId,
        content: this.detailContent,
      });
    },
    // 修改详情
    detailLoadIframe() {
      if (this.detailContent) {
        var v_Obj = document.getElementById("detailUeditor").contentWindow;
        v_Obj.setContent(this.detailContent);
      } else {
        setTimeout(this.detailLoadIframe, 300);
      }
    },
    changeDetailFn(businessId) {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.detailContent = "";
      this.businessId = businessId;
      this.$get("/sendmsg/pc/chat/discussBusiness/queryBaseInfoByBusinessId", {
        businessId: this.businessId,
      })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.detailContent = res.data.content;
            this.$Message.destroy();
            this.detailStatusFn(true);
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    detailStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.detailStatus = status;
          },
        });
      } else {
        this.detailStatus = status;
      }
    },
    // 议事公告
    commentManageStatusFn(status) {
      this.commentManageStatus = status;
    },
    // 修改基本内容
    backNextStep() {
      this.currentMenuName =
        this.currentMenuName == "baseInfo" ? "group" : "baseInfo";
    },
    changeBaseInfo() {
      this.businessId = this.discussManageData.businessId;
      this.currentMenuName = 'baseInfo';
      this.startend = this.$route.query.startend;
      this.baseInfoStatus = true;
    },
     saveBaseInfo() {
      this.$refs.baseInfo.saveBaseInfo();
    },
    baseInfoStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.baseInfoStatus = status;
          },
        });
      } else {
        this.baseInfoStatus = status;
      }
    },
    saveSuccessBaseInfo() {
      this.cancelModalTip = false;
      this.baseInfoStatusFn(false);
      this.getProcessData();
      this.getBaseInfoData();
    },
    // 结束议事
    endDiscuss() {
      this.$Modal.confirm({
        title: "温馨提示",
        content: `您正在结束 <span style="color: red">${this.discussManageData.title}</span> 的议事，是否继续`,
        onOk: () => {
          this.$post("/sendmsg/pc/chat/discussBusiness/updateStatus", {
            businessId: this.$route.query.businessId,
            staffId: parent.vue.loginInfo.userinfo.custGlobalId,
            staffName: parent.vue.loginInfo.userinfo.realName,
            status: "5",
          }).then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "操作成功",
              });
              this.$router.push({
                path: this.$route.path,
                query: {
                  ...this.$route.query,
                  startend: 'end'
                }
              });
              this.getProcessData()
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          });
        },
      });
    },
    // 议事讨论——管理
    discussSetting(businessId, showName) {
      this.showName = showName;
      this.businessId = businessId;
      this.discussSettingStatus = true;
    },
    discussSettingStatusFn(status) {
      this.discussSettingStatus = status;
    },
    // 删除此阶段
    deleteVoteOption(businessId,index) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: index == (this.discussManageData.child.length -1) ? '您正在删除顶级议事阶段，其下所有的议事阶段将会被同时删除，此操作不可逆，是否继续？' : "您正在删除议事阶段，此操作不可逆，是否继续？",
        onOk: () => {
          this.$get("/sendmsg/pc/chat/discussBusiness/deleteByBusinessId", {
            businessId,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "删除成功",
                });
                setTimeout(() => {
                  this.$Message.destroy();
                  if(index == (this.discussManageData.child.length -1)) {
                    this.$router.push('/discussionindex?menuId=' + this.$route.query.menuId)
                  }else {
                    this.getProcessData();
                  }
                }, 500);
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.$Message.error({
                background: true,
                content: "删除失败，请联系管理员处理",
              });
            });
        },
      });
    },
    // 修改投票项
    changeVoteOption(businessType, businessCode, businessId) {
      this.parentBusinessId = "";
      this.businessType = businessType;
      this.businessCode = businessCode;
      this.businessId = businessId;
      this.voteTitle = this.$route.query.startend == 'start' && this.buttonRoot == '1001' ? "修改议事投票项" : '查看议事投票项';
      this.nextDiscussVoteStatus = true;
    },
    // 新增下一阶段
    addNextDiscuss(name) {
      if (name == "nextDiscussVoteStatus") {
        //去掉businessType，businessCode，代表新增投票
        this.businessType = "";
        this.businessCode = "";
        this.voteTitle = "下阶段议事投票";
      }
      this[name] = true;
    },
    saveNextSuccess() {
      this.cancelModalTip = false;
      this.nextDiscussVoteStatus = false;
      this.nextDiscussAnnStatus = false;
      this.getProcessData();
    },
    // 新增下一阶段——公告
    saveNextAnn() {
      this.$refs.nextDiscussAnn.save();
    },
    nextDiscussAnnStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.nextDiscussAnnStatus = status;
          },
        });
      } else {
        this.nextDiscussAnnStatus = status;
      }
    },
    // 新增下一阶段——投票
    saveNextVote() {
      this.$refs.nextDiscussVote.save();
    },
    nextDiscussVoteStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.nextDiscussVoteStatus = status;
          },
        });
      } else {
        this.nextDiscussVoteStatus = status;
      }
    },
    // 新增下一阶段——讨论
    saveNextDiscussNotice() {
      if (!this.discussNoticeTime[0] || !this.discussNoticeTime[1]) {
        this.$Message.error({
          background: true,
          content: "请选择讨论开始时间和结束时间",
        });
        return;
      }
      this.$post("/sendmsg/pc/chat/discussBusiness/addNextDiscussBusiness", {
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        endTime: this.$core.formatDate(
          new Date(this.discussNoticeTime[1]),
          "yyyy-MM-dd hh:mm:ss"
        ),
        startTime: this.$core.formatDate(
          new Date(this.discussNoticeTime[0]),
          "yyyy-MM-dd hh:mm:ss"
        ),
        modifier: parent.vue.loginInfo.userinfo.realName,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        parentBusinessId: this.parentBusinessId,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "下阶段议事讨论新增成功",
            });
            setTimeout(() => {
              this.$Message.destroy();
            this.discussNoticeTime = [];
            this.nextDiscussNoticeStatus = false;
              this.getProcessData();
            },500)
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "新增失败，请联系管理员处理",
          });
        });
    },
    // 议事投票单选多选规则
    changeVoteRule(obj) {
      this.voteRuleData = obj;
      this.voteRuleStatus = true;
    },
    saveVoteRule() {
      if (
        this.voteRuleData.voteType == "2" &&
        !this.voteRuleData.selectMinItem
      ) {
        this.$Message.error({
          background: true,
          content: "请设置最少需选择的选项数",
        });
        return;
      }
      if (
        this.voteRuleData.voteType == "2" &&
        !this.voteRuleData.selectMaxItem
      ) {
        this.$Message.error({
          background: true,
          content: "请设置最多可选择的选项数",
        });
        return;
      }
      let params = {};
      if (this.voteRuleData.voteType == "2") {
        params = this.voteRuleData;
      } else {
        params.businessId = this.voteRuleData.businessId;
        params.voteType = this.voteRuleData.voteType;
      }
      params.modifier = parent.vue.loginInfo.userinfo.realName;
      params.oemCode = parent.vue.oemInfo.oemCode;

      this.$post("/sendmsg/pc/chat/discussBusiness/updateVoteType", params)
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "保存成功",
            });
            setTimeout(() => {
              this.$Message.destroy();
              this.getProcessData();
            this.voteRuleStatus = false;
            },500)
            
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "保存失败，请联系管理员处理",
          });
        });
    },
    voteRuleStatusFn(status) {
      this.voteRuleStatus = status;
    },
    loadUeditor(item, index) {
      if (item.content != "" && item != null && item != undefined) {
        let id = "ueditor" + index;
        var v_Obj = document.getElementById(id).contentWindow; //获取iframe对象
        v_Obj.setContent(item.content); //写入编辑器富文本内容
        v_Obj.setPreview(true);
      } else {
        setTimeout(this.loadUeditor, 300);
      }
    },
    // 修改时间
    changeTime(startTime, endTime, businessId) {
      this.timeArr = [startTime, endTime];
      this.businessId = businessId;
      this.timeStatus = true;
    },
    saveTimeChange() {
      if (!this.timeArr || this.timeArr[1] == "" || this.timeArr[0] == "") {
        this.$Message.error({
          background: true,
          content: "请选择议事开始时间和结束时间",
        });
        return;
      }
      let obj = {
        businessId: this.businessId,
        endTime: this.$core.formatDate(
          new Date(this.timeArr[1]),
          "yyyy-MM-dd hh:mm:ss"
        ),
        startTime: this.$core.formatDate(
          new Date(this.timeArr[0]),
          "yyyy-MM-dd hh:mm:ss"
        ),
      };
      this.changeDiscussRule(obj);
    },
    // 议事规则改变
    changeDiscussRule(obj) {
      this.$post("/sendmsg/pc/chat/discussBusiness/updateBusinessRule", obj)
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "操作成功",
            });
            setTimeout(() => {
              this.cancelModalTip = false;
              this.timeStatus = false;
              this.detailStatus = false;
              this.getProcessData();
              this.$Message.destroy();
            }, 500);
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "操作失败，请联系管理员处理",
          });
        });
    },
    //   切换标签
    toggleTab(name) {
      if (
        name == "content" &&
        JSON.stringify(this.discussionBaseInfoForm) === "{}"
      ) {
        this.getBaseInfoData();
      }
    },
    // 获取议题基本内容数据
    getBaseInfoData() {
      this.$get("/sendmsg/pc/chat/discussBusiness/queryBaseInfoByBusinessId", {
        businessId: this.$route.query.businessId,
      })
        .then((res) => {
          if (res.code == 200 && res.data) {
            let regions = [];
            if (res.data.regions) {
              res.data.regions.map((item) => {
                regions.push(item.regionName);
              });
            }
            this.discussionBaseInfoForm = {
              title: res.data.title,
              source: res.data.source,
              content: res.data.content,
              regions: regions,
            };
            this.loadBaseInfoIframe();
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
            this.discussionBaseInfoForm = {content: ''};
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    // 获取议事进程数据
    getProcessData() {
      if (this.$route.query.businessId) {
        this.$Message.loading({
          content: "正在加载数据，请稍等...",
          duration: 0,
        });
        this.$get(
          "/sendmsg/pc/chat/discussBusiness/queryDetailInfoByBusinessId",
          {
            businessId: this.$route.query.businessId,
          }
        )
          .then((res) => {
            if (res.code == 200 && res.data) {
              this.discussManageData = res.data;
              this.discussManageData.child.push({
                businessId: this.discussManageData.businessId,
                businessType: this.discussManageData.businessType,
                chatDiscuss: this.discussManageData.chatDiscuss,
                chatImg: this.discussManageData.chatImg,
                chatText: this.discussManageData.chatText,
                content: this.discussManageData.content,
                endTime: this.discussManageData.endTime,
                startTime: this.discussManageData.startTime,
                status: this.discussManageData.status,
                title: this.discussManageData.title,
                voteInfo: this.discussManageData.voteInfo,
                adminCount: this.discussManageData.adminCount,
                participantCount: this.discussManageData.participantCount,
                recordCount: this.discussManageData.recordCount,
              });
              this.discussManageData.child.map((item, index) => {
                //修改基本内容时，更新富文本数据
                let id = "ueditor" + index;
                if (document.getElementById(id)) {
                  var v_Obj = document.getElementById(id).contentWindow; //获取iframe对象
                  v_Obj.setContent(item.content); //写入编辑器富文本内容
                  v_Obj.setPreview(true);
                }
              });
              this.$Message.destroy();
            } else {
              this.$Message.destroy();
              this.$Message.error({
                background: true,
                content: res.desc,
              });
              this.discussManageData = {
                child: []
              }
            }
          })
          .catch((err) => {
            this.$Message.destroy();
            console.log(err);
            this.$Message.error({
              background: true,
              content: "获取数据失败，请联系管理员处理",
            });
          });
      }
    },
  },
  filters: {
    filterTime(val, that) {
      if (val) {
        return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss");
      } else {
        return "";
      }
    },
  },
  created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
    let userButtonRoot = parent.vue.userButtonRoot;
    this.buttonRoot = userButtonRoot.includes("1001")
      ? "1001"
      : userButtonRoot.includes("1002")
      ? "1002"
      : userButtonRoot.includes("1003")
      ? "1003"
      : "";
      // this.buttonRoot = '1003';
    this.getProcessData();
    this.parentBusinessId = this.$route.query.businessId;
  },
  components: {
    LiefengModal,
    LiefengContent,
    NextDiscussAnn,
    NextDiscussVote,
    DiscussSetting,
    CommentManage,
    LiefengTable,
    BaseInfo
  },
};
</script>
    
<style scoped lang='less'>
.tab {
  /deep/.ivu-tabs-content {
  height: calc(100vh - 169px);
  .ivu-tabs-tabpane {
    height: 100%;
    overflow: auto;
  }
}
}

/deep/.last-time {
  padding-left: 126px;
  .ivu-timeline-item-head-custom {
    width: 56px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    background: #aaa;
    color: #fff;
    padding: 0;
    margin-top: 16px;
    left: -15px;
  }
  .last-time {
    width: 50px;
    height: 36px;
    border-radius: 50%;
    background: #aaa;
    color: #fff;
  }
}
.time-line {
  display: flex;

  /deep/.ivu-timeline-item-tail {
    display: inline-block !important;
    border-left: 2px solid #e8eaec;
  }
  /deep/.ivu-timeline-item-head-custom {
    text-align: center;
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #2d8cf0;
    padding: 1px;
    top: 3px;
    left: -2px;
  }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.modal {
  /deep/.ivu-modal-close {
    display: none;
  }
}
 /deep/.ivu-input[disabled], fieldset[disabled] .ivu-input,/deep/.ivu-select-disabled .ivu-select-selection,/deep/.ivu-radio-disabled .ivu-radio-inner {
        background-color: #fff!important;
        color: #515a6e;
    }
     /deep/.ivu-radio-disabled .ivu-radio-inner,/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
      border: 1px solid #515a6e
    }
    /deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after,/deep/.ivu-radio-disabled .ivu-radio-inner:after {
      border-color: #515a6e;
    }
    /deep/.ivu-radio-disabled .ivu-radio-inner:after {
      background-color: #888;
    }
</style>