<template>
    <div>
        <Tabs type="card" :animated="false" @on-click="toggleTabs" v-model="hidden">

            <TabPane label="全部留言" name="0"></TabPane>
            <TabPane label="未显示留言" name="1"></TabPane>
            <TabPane label="已显示留言" name="2"></TabPane>
            <div slot="extra">
                <Button type="primary" icon="ios-cloud-download-outline" style="margin-right: 10px" @click="exportExcel">导出</Button>
                <Button type="error" icon="ios-arrow-back" @click="$emit('commentManageStatusFn',false)">返回</Button>
            </div>
        </Tabs>
        <LiefengTable
            :tableData="tableData"
          :talbeColumns="talbeColumns"
          :loading="loading"
          :pageSize="pageSize"
          :pagesizeOpts="[20, 30, 50, 100, 200]"
          :total="total"
          :height="tableHeight"
          :curPage="page"
          @hadlePageSize="hadlePageSize"
        ></LiefengTable>
    </div>
</template>

<script>
import LiefengTable from '@/components/LiefengTable'
   export default {
       props: ['businessId'],
       data() {
            return {
              hidden: '0',
                tableData: [],
                talbeColumns: [
                    {
                        title: '留言详情',
                        minWidth: 200,
                        align: 'center',
                        render: (h,params) => {
                            return h('div',{
                                style: {
                                    display: 'flex',
                                    textAlign: 'left',
                                    padding: '6px 0'
                                }
                            },[
                                h('div',{
                                    style: {
                                        width: '80px',
                                        height: '80px',
                                        overflow: 'hidden',
                                        borderRadius: '50%',
                                        border: '1px solid #e8eaec',
                                        marginRight: '16px',
                                        boxShadow: '0 0 5px 0 rgba(0,0,0,.2)'
                                    }
                                },[
                                    h('img',{
                                        attrs: {
                                            src: params.row.portraitUrl
                                        },
                                        style: {
                                            width: '80px',
                                            height: '80px',
                                            display: params.row.portraitUrl ? 'block' : 'none',
                                        }
                                    })
                                ]),
                                h('div',{
                                    style: {
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }
                                },[
                                    h('h3',{
                                        style: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }
                                    },params.row.nickName),
                                    h('p',{
                                        style: {
                                            textIndent: '2em'
                                        }
                                    },params.row.content),
                                    h('span',{
                                        style: {
                                            color: 'rgba(0,0,0,.45)'
                                        }
                                    },params.row.gmtCreate)
                                ])
                            ])
                        }
                    },
                    {
                        title: '显示状态',
                        width: 100,
                        align: 'center',
                        render: (h,params) => {
                            return h('i-switch',{
                                props: {
                                    value: params.row.hidden,
                                    trueValue: '1',
                                    falseValue: '2',
                                    size: 'large'
                                },
                                on: {
                                    'on-change': (status) => {
                                       this.$post('/old/api/pc/componentDiscuss/updateHidden',{
                                           hidden: status,
                                           discussId: params.row.discussId
                                       }).then(res => {
                                           if(res.code == 200) {
                                               this.$Message.success({
                                                   background: true,
                                                   content: "操作成功"
                                               })
                                           }else {
                                               this.$Message.error({
                                                   background: true,
                                                   content: res.desc
                                               });
                                               this.tableData = [];
                                              this.hadlePageSize({
                                                  page: this.page,
                                                  pageSize: this.pageSize
                                              })
                                           }
                                       }).catch(err => {
                                           console.log(err);
                                           this.$Message.error({
                                                   background: true,
                                                   content: "操作失败，请联系管理员处理"
                                               })
                                       })
                                    }
                                }
                            },[
                                h('span',{
                                    slot: 'open',
                                },'显示'),
                                h('span',{
                                    slot: 'close'
                                },'隐藏'),
                            ])
                        }
                    },
                    {
                        title: '年龄',
                        width: 80,
                        align: 'center',
                        key: 'age',
                    },
                    {
                        title: '性别',
                        width: 80,
                        align: 'center',
                        key: 'sex',
                    },
                    {
                        title: '真实姓名',
                        width: 140,
                        align: 'center',
                        key: 'name',
                    },
                    {
                        title: '身份类型',
                        width: 170,
                        align: 'center',
                        key: 'userType',
                    },
                    {
                        title: '所属社区',
                        width: 170,
                        align: 'center',
                        key: 'communityName',
                    },
                    {
                        title: '所在详细地址',
                        width: 220,
                        align: 'center',
                        key: 'addr',
                    },
                    {
                        title: '操作',
                        width: 90,
                        align: 'center',
                        render: (h,params) => {
                            return h('Button',{
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                on: {
                                    click: () =>{
                                        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在删除留言信息，此操作不可逆，是否继续？",
          onOk: () => {
            this.$get('/sendmsg/pc/chat/discussBusiness/deleteByDiscussId',{
              discussId: params.row.discussId
            }).then(res => {
              if(res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: '删除成功'
                });
                this.hadlePageSize({
                      page: this.page,
                      pageSize: this.pageSize
                  });
              }else {
                this.$Message.error({
                  background: true,
                  content: res.desc
                })
              }
            }).catch(err => {
              console.log(err);
              this.$Message.error({
                background: true,
                content: "删除失败，请联系管理员处理"
              })
            })
          },
        });
                                    }
                                }
                            },"删除")
                        }
                    },
                ],
                loading: false,
                pageSize: 20,
                total: 0,
                page: 1,
                tableHeight: '',

           }
        },
        methods: {
            
            // 导出
            exportExcel() {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content:
                      "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
                    onOk: () => {
                      let tHeader = ['留言详情','显示状态','年龄','性别','真实姓名','身份类型','所属社区','所在详细地址'];
                      let filterVal = ['content','hidden','age','sex','name','userType','communityName','addr'];
                    let tableData = this.tableData.map(item => {
                        return {
                            ...item,
                            content: item.nickName + ':' + item.content,
                            hidden: item.hidden == '1' ? '显示' : '隐藏'
                        }
                    })
                      this.$core.exportExcel(
                        tHeader,
                        filterVal,
                        tableData,
                        "评论管理列表"
                      );
                   },
                 });
            },
            toggleTabs(name) {
                this.hidden = name;
                this.hadlePageSize({
                    page: this.page,
                    pageSize: this.pageSize
                })
            },
            hadlePageSize(obj) {
                this.loading = true;
                this.$get('/sendmsg/pc/chat/discussBusiness/queryDiscussRecordPage',{
                    businessId: this.businessId,
                    hidden: this.hidden,
                    page: obj.page,
                    pageSize: obj.pageSize
                }).then(res => {
                    if(res.code == 200 && res.dataList) {
                        res.dataList.map(item => {
                            if(item.sex) {
                                item.sex = item.sex == '1' ? '男' : item.sex == '2' ? '女' : '未知'
                            }
                            if(item.gmtCreate) {
                                item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate),'yyyy-MM-dd hh:mm:ss');
                            }
                            if(item.userType) {
                                switch (item.userType) {
                                    case 1: 
                                        item.userType = '仅户籍在此';
                                        break;
                                    case 2: 
                                        item.userType = '是业主但户籍不在此';
                                        break;
                                    case 3: 
                                        item.userType = '是业主且户籍在此';
                                        break;
                                    case 4: 
                                        item.userType = '租赁人员';
                                        break;
                                    default: 
                                        item.userType = '其他';
                                        break;
                                }
                            }
                        });
                        this.tableData = res.dataList;
                        this.page = res.currentPage;
                        this.pageSize = res.pageSize;
                        this.total = res.maxCount;
                        this.loading = false;
                    }else {
                        this.loading = false;
                        this.$Message.error({
                            background: true,
                            content: res.desc
                        })
                    }
                }).catch(err => {
                    console.log(err);
                    this.$Message.error({
                        background: true,
                        content: "获取数据包失败，请联系管理员处理"
                    })
                })
            }
        },
        created() {
            this.tableHeight = window.innerHeight - 120 + '';
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize
            })
        },
        components: {
            LiefengTable
        }
      
    }
</script>
    
<style scoped lang='less'>
    
</style>